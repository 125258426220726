<template>
    <div class="w-100 my-data">
        <validation-observer ref="userDataObserver">
            <h1 class="mb-3 mx-1" style="font-weight: bolder; color: #72A5D8; font-size: xx-large">Moji Podatki</h1>
            <BTabs class="tab_hover">
                <BTab title="Glavni podatki">
                    <BCard class="cardWidth" style="background-color: #e6e7e6">
                        <validation-provider #default="{ errors }" name="Ime" rules="required|min:3|max:30">
                            <InputField :profile="true" label="Ime" class="mb-1" v-model="userObject.name"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Priimek" rules="required|min:3|max:30">
                            <InputField :profile="true" label="Priimek" class="my-1" v-model="userObject.surname"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Naslov 1" rules="required|min:2|max:50">
                            <InputField :profile="true" label="Naslov 1" class="my-1" v-model="userObject.primary_address.street"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Naslov 2" rules="">
                            <InputField :profile="true" label="Naslov 2" class="my-1" v-model="userObject.primary_address.street2"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <div class="d-md-flex" style="margin-bottom: 1rem">
                            <validation-provider class="w-50" #default="{ errors }" name="Mesto" rules="required|min:2|max:35">
                                <InputField :profile="true" label="Mesto" class="mr-1 w-100" v-model="userObject.primary_address.city"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider class="w-50 ml-2" #default="{ errors }" name="Poštna številka" rules="required|min:4|max:4">
                                <InputField :profile="true" label="Poštna številka" class="w-100" v-model="userObject.primary_address.post"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                        <InputField :profile="true" label="Država" :is-disabled="true" class="country-input" v-model="userObject.primary_address.country"/>
                        <validation-provider #default="{ errors }" name="Šifra plačnika" rules="required">
                            <InputField :profile="true" label="Šifra plačnika" class="mb-1 mt-2" v-model="userObject.code_payer"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                            <InputField :profile="true" label="E-mail" class="my-1" v-model="userObject.email"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Telefon" rules="required|phone_number">
                            <InputField :profile="true" label="Telefon" class="my-1" v-model="userObject.telephone_number"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Geslo" rules="">
                            <InputField :profile="true" label="Geslo" class="my-1" :password="true" v-model="userObject.password"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Novo Geslo" rules="min:8|at_least_one_uppercase|at_least_one_digit">
                            <InputField :profile="true" label="Novo Geslo" class="mt-1" :password="true" v-model="userObject.newPassword"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <div class="mt-5 mb-1 myData">
                            <validation-provider #default="{ errors }" name="dovoljenje">
                                <div class="row">
                                    <div class="col-2">
                                        <b-form-group>
                                            <label class="check m-0">
                                                <input type="checkbox" v-model="userObject.marketing_permission" name="dovoljenje"/>
                                                <span class="remember mark"></span>
                                            </label>
                                        </b-form-group>
                                    </div>
                                    <div class="col-10">
                                        <span style="font-size: small">Dajem dovoljenje za neposredno trženje (prejemanje novic in obvestil komercialne narave na vaš e-naslov in telefon - občasni SMS/MMS, telefonski klic)</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </div>
                            </validation-provider>
                        </div>
                        <div class="mb-3 myData">
                            <validation-provider #default="{ errors }" name="pogoji poslovanja" :rules="{ required: { allowFalse: false } }">
                                <div class="row">
                                    <div class="col-2">
                                        <label class="check m-0">
                                            <input type="checkbox" v-model="statusTermsAndConditions" name="statusTermsAndConditions"/>
                                            <span class="remember mark"></span>
                                        </label>
                                    </div>
                                    <div class="col-10">
                                        <span style="font-size: small">Strinjam se s <a href="/pogoji-poslovanja" target="_blank">pogoji poslovanja</a> in <a href="/pogoji-varovanja-osebnih-podatkov" target="_blank">pravili zasebnosti</a></span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </div>
                            </validation-provider>
                        </div>
                        <div class="d-flex justify-content-center">
                            <rounded-button class="w-50"  @click="validateData">Posodobi podatke</rounded-button>
                        </div>
                    </BCard>
                </BTab>
                <BTab title="Drugi naslovi">
                    <BCard class="w-100" style="background-color: #e6e7e6;">
                        <div class="d-flex justify-content-center mb-2 mt-0">
                            <rounded-button style="margin-left: 1rem; height: fit-content; width: 25rem" @click="ModalShowNewAddress=true">Dodaj nov naslov</rounded-button>
                        </div>
                        <div>
                            <div v-for="(address,index) in secondary_addresses" :key="`sec_add_${index}`">
                                <div class="hr" style="margin: 1rem 0rem 1rem 0rem"/>
                                <AddressCard v-on:refresh-address-delete="getSecondaryAddress" v-on:refresh-address="getSecondaryAddress" :address="address" />
                                <div class="hr" style="margin: 1rem 0rem 1rem 0rem"/>
                            </div>
                        </div>
                    </BCard>
                </BTab>
            </BTabs>
        </validation-observer>
        <ModalAddress :profile="true" :address-object="{name: userObject.name, surname: userObject.surname}" :modal-show="ModalShowNewAddress" v-on:refresh="getSecondaryAddress" @close="ModalShowNewAddress=false"/>
    </div>
</template>

<script>
    import {BCard, BTab, BTabs, BFormGroup} from 'bootstrap-vue'
    import InputField from '@/views/Components/InputField'
    // import checkboxField from '@/views/Components/CheckboxField'
    import AddressCard from '@/views/Components/AddressCard'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations' //email, min, atLeastOneDigit, atLeastOneUppercase
    import ModalAddress from '@/views/Components/ModalAddress'
    const countries = require('i18n-iso-countries')
    countries.registerLocale(require('i18n-iso-countries/langs/sl.json'))
    // import VuePhoneNumberInput from 'vue-phone-number-input'
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import roundedButton from '@/views/Components/RoundedButton'

    export default {
        components: {InputField, AddressCard, BCard, BTab, BTabs, ValidationProvider, ValidationObserver, ModalAddress, roundedButton, BFormGroup},
        data() {
            return {
                statusTermsAndConditions: null,
                userObject: {
                    name: '',
                    surname: '',
                    primary_address: {
                        street: '',
                        street2: '',
                        post: '',
                        city: '',
                        country: '',
                        country_code: '',
                        active: 'true'
                    },
                    code_payer: '',
                    email: '',
                    telephone_number: '',
                    password: '',
                    newPassword: '',
                    marketing_permission: false
                },
                secondary_addresses: [],
                selectedSecondaryAddress: null,
                required,
                email,
                phoneInputValidatorTranslations: {
                    countrySelectorLabel: 'Mednarodna št.',
                    countrySelectorError: 'Napaka pri državi',
                    phoneNumberLabel: 'Telefonska številka',
                    example: 'Primer:'
                },
                isErrorPhoneNumber: false,
                phoneNumberValidatorResults: null,
                ModalShowNewAddress: false
            }
        },
        methods: {
            onPhoneNumberUpdate(validatorResult) {
                this.phoneNumberValidatorResults = validatorResult
                this.isErrorPhoneNumber = !validatorResult.isValid
            },

            validateData() {
                this.$refs.userDataObserver.validate().then(success => {
                    if (success) {
                        this.updateData()
                    }
                })
            },

            loadData() {
                const thisIns = this

                thisIns.$http.get('/api/user/v1/')
                    .then(res => {
                        thisIns.userObject = JSON.parse(JSON.stringify(res.data))
                        thisIns.getSecondaryAddress()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },

            updateData() {
                const thisIns = this
                thisIns.$http.patch('/api/user/v1/', thisIns.userObject)
                    .then(() => {
                        thisIns.loadData()
                        thisIns.$printSuccess('Podatki uspešno posodobljeni!')
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri posodabljanju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },

            getSecondaryAddress() {
                const thisIns = this
                thisIns.$http.get('/api/user/v1/address/')
                    .then(res => {
                        thisIns.secondary_addresses = JSON.parse(JSON.stringify(res.data))
                        this.ModalShowNewAddress = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju naslova je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        computed: {
            mapSecondAddressArray() {
                return this.userObject.secondary_addresses.map((el, ind) => { return {index: ind, title:`${el.name} ${el.surname}\n${el.street} ${el.street2}\n${el.city} ${el.post}\n${el.country}` } })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style lang="scss" scoped>

.input {
    background-color: #F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
    border-radius: 0 !important;
}
.input::placeholder{
    text-transform: uppercase;
    color: black;
}

.my-data .card-body{
    background: white !important;
    box-shadow: none !important;
}

.my-data .card{
    background: white !important;
    box-shadow: none !important;
}

.hr {
    border-bottom: 2px solid #4d4d4d;
    width: 100%;
}

tab_hover {
    background-color: #479DD3;
}

@media only screen and (max-width: 450px) {
    .cardWidth {
        width: 100%;
    }
}

@media only screen and (min-width: 450px) {
    .cardWidth {
        width: 75%;
    }
}

.country-input {
  width: 100%;
  padding-right: 0;
}
@media only screen and (min-width: 768px) {
  .country-input {
    width: 50%;
    padding-right: 0.7rem;
  }
}
</style>

<style>

    .myData .check {
        display: inline-block;
        position: relative;
        cursor: pointer;

    }
    .myData .check input {
        opacity: 0;
    }
    .myData .mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        background-color: #fff;
        padding: 0px !important;
    }
    
    .myData .mark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .myData .check input:checked ~ .mark:after {
        display: block;
    }

    .myData .check .mark{
        width: 21px;
        height: 21px;
        border: 1px solid black;
        border-radius:50%;
        outline:none;
    }
    .myData .check .mark:after {
        width: 100%;
        border: 4px solid white;
        height: 100%;
        background-color: #72A5D8;
        border-radius: 50% !important;
    }
    .myData .form-control,
    .myData .input-group-text {
        border-radius: 0px !important;
    }
    .myData .form-control {
        background-color: #F4F4F4 !important;
        padding-top: 1.75em !important;
        padding-bottom: 1.75em !important;
        border: none;
    }
    .myData .form-control:focus {
        box-shadow: none;
        transition: border-color 0.15s ease-in-out !important;
    }
    .myData .form-control::placeholder {
        color: black !important;
    }

</style>
